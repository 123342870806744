import React, { useEffect,useState } from "react";
// white diam
import { Shape } from "./Basic/Shape"
import { Price } from "./Basic/Price";
import { Carat } from "./Basic/Carat";
import { Color } from "./Basic/Color";
import { Cut } from "./Basic/Cut";
import { Clarity } from "./Basic/Clarity";
// fancy white diam
import { ShapeFancy } from "./BasicFancy/Shape"
import { PriceFancy } from "./BasicFancy/Price";
import { CaratFancy } from "./BasicFancy/Carat";
import { FancyColor } from "./BasicFancy/FancyColor";
import { CutFancy } from "./BasicFancy/Cut";
import { ClarityFancy } from "./BasicFancy/Clarity";
//lab diam
import { ShapeLab } from "./BasicLab/Shape"
import { PriceLab } from "./BasicLab/Price";
import { CaratLab } from "./BasicLab/Carat";
import { ColorLab } from "./BasicLab/Color";
import { CutLab } from "./BasicLab/Cut";
import { ClarityLab } from "./BasicLab/Clarity";
//lab fancy white diam
import { ShapeLabFancy } from "./BasicLabFancy/Shape"
import { PriceLabFancy } from "./BasicLabFancy/Price";
import { CaratLabFancy } from "./BasicLabFancy/Carat";
import { FancyLabColor } from "./BasicLabFancy/FancyColor";
import { CutLabFancy } from "./BasicLabFancy/Cut";
import { ClarityLabFancy } from "./BasicLabFancy/Clarity";


import axios from "axios";
import { NODE_API_URL } from "../../../../../env_config";
import { useSelector } from "react-redux";
// import { SEARCH_LOG } from "../../../../../env_config";
const CancelToken = axios.CancelToken;
let cancel;
let searchcancel;
export const Basic = ({formik,setSearchData,diamond_type,custompage,setLoader,sorting,fancy,ruleData,naturalData,naturalFancyData,labData,labFancyData,setFancy,setFancyLab,fancyLab,shortlist,language,location,setStoneCount,setCustomPage,searchdata,stoneCount}) => {
    const [timerId, setTimerId] = useState(null);
    const { hidePrice } = useSelector((state) => state.CcModeSettingReducer);
    useEffect(() => {
        if(custompage === 1){
        setSearchData([])   
        let userData = localStorage.getItem("userData");
        let ConsumerId = localStorage.getItem("C_id"); 
        if(userData){
            userData = JSON.parse(userData)
        }
        if(ConsumerId){
            ConsumerId = JSON.parse(ConsumerId)
        }
        let FinalObject = {
            page:custompage,
            user_id:userData.CustomerId,
            ConsumerId:ConsumerId,
            currency:localStorage.getItem("user_currency")
        }
        
        if(formik.values.Shape && formik.values.Shape.length){
            FinalObject["shape"] = formik.values.Shape
        }
        if(formik.values.Cut && formik.values.Cut.length){
            FinalObject["cut"] = formik.values.Cut
        }
        if(formik.values.Clarity && formik.values.Clarity.length){
            FinalObject["clarity"] = formik.values.Clarity
        }
        if(formik.values.Color && formik.values.Color.length){
            FinalObject["color"] = formik.values.Color
        }
        if(fancy && diamond_type === "N"){
            FinalObject["fancy_color_diamond"] = "YES"
        }
        if(fancyLab && diamond_type === "L"){
            FinalObject["fancy_color_diamond"] = "YES"
        }

        if(formik.values.Fancy_Color && formik.values.Fancy_Color.length){
            // FinalObject["fancy_color_diamond"] = "YES"
            FinalObject["fancy_color"] = formik.values.Fancy_Color
        }
        if(formik.values.Fancy_Color_Overtone && formik.values.Fancy_Color_Overtone.length){
            // FinalObject["fancy_color_diamond"] = "YES"
            FinalObject["fancy_overtone"] = formik.values.Fancy_Color_Overtone
        }
        if(formik.values.Fancy_Color_Intensity && formik.values.Fancy_Color_Intensity.length){
            // FinalObject["fancy_color_diamond"] = "YES"
            FinalObject["fancy_intensity"] = formik.values.Fancy_Color_Intensity
        }
        if(formik.values.Lab && formik.values.Lab.length){
            FinalObject["lab"] = formik.values.Lab
        }
        if(formik.values.Polish && formik.values.Polish.length){
            FinalObject["polish"] = formik.values.Polish
        }
        if(formik.values.Symmetry && formik.values.Symmetry.length){
            FinalObject["symmetry"] = formik.values.Symmetry
        }
        if(formik.values.Fluorescence_Intensity && formik.values.Fluorescence_Intensity.length){
            FinalObject["fluorescence"] = formik.values.Fluorescence_Intensity
        }
        if(formik.values.minCarat >= 0){
            FinalObject["min_carat"] = formik.values.minCarat
        }
        if(formik.values.maxCarat >= 0){
            FinalObject["max_carat"] = formik.values.maxCarat
        }
        if(formik.values.minPrice >= 0){
            FinalObject["total_price_from"] = formik.values.minPrice
        }
        if(formik.values.maxPrice >= 0){
            FinalObject["total_price_to"] = formik.values.maxPrice
        }

        if(formik.values.minDepth >= 0){
            FinalObject["depthmin"] = formik.values.minDepth
        }
        if(formik.values.maxDepth >= 0){
            FinalObject["depthmax"] = formik.values.maxDepth
        }
        if(formik.values.minTable >= 0){
            FinalObject["tablemin"] = formik.values.minTable
        }
        if(formik.values.maxTable >= 0){
            FinalObject["tablemax"] = formik.values.maxTable
        }
        if(formik.values.minRatio >= 0){
            FinalObject["ratiomin"] = formik.values.minRatio
        }
        if(formik.values.maxRatio >= 0){
            FinalObject["ratiomax"] = formik.values.maxRatio
        }
        if(sorting === "carat_desc"){
            FinalObject["sort_field"] = "carat"
            FinalObject["sort_order"] = "desc"
        }
        if(sorting === "carat_asc"){
            FinalObject["sort_field"] = "carat"
            FinalObject["sort_order"] = "asc"
        }
        if(sorting === "price_desc"){
            FinalObject["sort_field"] = "price"
            FinalObject["sort_order"] = "desc"
        }
        if(sorting === "price_asc"){
            FinalObject["sort_field"] = "price"
            FinalObject["sort_order"] = "asc"
        }
        if(sorting === "clarity_desc"){
            FinalObject["sort_field"] = "clarity"
            FinalObject["sort_order"] = "desc"
        }
        if(sorting === "clarity_asc"){
            FinalObject["sort_field"] = "clarity"
            FinalObject["sort_order"] = "asc"
        }
        if(sorting === "color_desc"){
            FinalObject["sort_field"] = "color"
            FinalObject["sort_order"] = "desc"
        }
        if(sorting === "color_asc"){
            FinalObject["sort_field"] = "color"
            FinalObject["sort_order"] = "asc"
        }
        let len = Object.keys(FinalObject).length;
            const temp = {...FinalObject};
            let data = "";
            for (let key in temp) {
                len--;
                let arr = temp[key];
                if (len) {
                    if (Array.isArray(arr)) {
                        let str = "";
                        str += "'" + arr.join("','") + "'";
                        data += `{"FilterName": "${key}","FilterValue":"${str}" }, `;
                    } else {
                        data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"},`;
                    }
                } else {
                    if (Array.isArray(arr)) {
                        let str = "";
                        str += "'" + arr.join("','") + "'";
                        data += `{"FilterName": "${key}","FilterValue":"${str}" } `;
                    } else {
                        data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"}`;
                    }
                }
            }
            // const finalData = `{ "JsonData":  [${data}] , "CustomerId": ${userData.CustomerId}, "ConsumerId":${ConsumerId=== "" ? "-1" : ConsumerId}, "WLDiamondType": "${diamond_type}" }`;
            // axios({
            //     method: "POST",
            //     url: SEARCH_LOG,
            //     headers: { "Content-Type": "application/json" },
            //     data: finalData,
            // })
            //     .then(function(res) {
            //         // console.log(res);
            //     })
            //     .catch(function(res) {
            //         console.log(res);
            //     });
        setLoader(true)
            if (timerId) {
                clearTimeout(timerId);
            }
            // Cancel the previous request if it exists
            if (cancel) {
                cancel();
              }
      
              // Create a new cancel token for the current request
              const newCancelToken = new CancelToken(function executor(c) {
                cancel = c;
              });
            const newTimerId = setTimeout(() => {
            let LogObj = {...FinalObject,diamond_type:diamond_type}
            delete LogObj.token
            if(LogObj.fancy_color_diamond === "YES"){
                delete LogObj.color
            }else{
                delete LogObj.fancy_color
                delete LogObj.fancy_intensity
                delete LogObj.fancy_intensity
            }
            axios({
                method: "POST",
                url: `${NODE_API_URL}/ccmode/storeUserACtivity`,
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                  },
                data: {
                    user_id:FinalObject.user_id,
                    activity_type:"Ccmode Search",
                    user_agent:navigator.userAgent,
                    activity_data:LogObj,
                    ip_address:localStorage.getItem("ipData"),
                    consumer_id:ConsumerId,
                    diamond_type:diamond_type
                },
                cancelToken:newCancelToken
            }).then(res => {
                if(res.data.message === "UnAuthorized"){
                    localStorage.clear();
                    window.location.reload(true)
                  }
            }).catch(error => {
            })
          }, 6000);
          setTimerId(newTimerId);
            if(diamond_type === "N"){
                if (searchcancel) {
                    searchcancel();
                  }
          
                  // Create a new cancel token for the current request
                  const newCancelTokenSearch = new CancelToken(function executor(c) {
                    searchcancel = c;
                  });
                axios({
                    method: "POST",
                    url: `${NODE_API_URL}/ccmode/fetchCCModeNaturalStones`,
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                      },
                    data: FinalObject,
                    cancelToken:newCancelTokenSearch
                }).then(res => {
                    setLoader(false)
                    if(res && res.data && res.data.success){
                        if(res.data.message === "UnAuthorized"){
                            localStorage.clear();
                            window.location.reload(true)
                          }
                        setSearchData(res.data)
                        axios({
                            method: "POST",
                            url: `${NODE_API_URL}/ccmode/fetchCCDiamondCount`,
                            headers: {
                                "Access-Control-Allow-Origin": "*",
                                "Content-Type": "application/json",
                                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                              },
                            data: {
                                ...FinalObject,
                                diamond_type:FinalObject["fancy_color_diamond"] === "YES"?diamond_type+"F":diamond_type
                            },
                        }).then(countres => {
                            if(countres && countres.data){
                                if(countres.data.message === "UnAuthorized"){
                                    localStorage.clear();
                                    window.location.reload(true)
                                  }
                                setStoneCount(countres.data.diamondcount)
                            }
                        })
                    }
                    else{
                        setSearchData([])
                        setStoneCount(0)
                    }
                }).catch(err => {
                    // setLoader(false)
                })
            }
            if(diamond_type === "L"){
                if (searchcancel) {
                    searchcancel();
                  }
          
                  // Create a new cancel token for the current request
                  const newCancelTokenSearch = new CancelToken(function executor(c) {
                    searchcancel = c;
                  });
                axios({
                    method: "POST",
                    url: `${NODE_API_URL}/ccmode/fetchCCModeLabStones`,
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                      },
                    data: FinalObject,
                    cancelToken:newCancelTokenSearch
                }).then(res => {
                    setLoader(false)
                    if(res && res.data && res.data.success){
                        if(res.data.message === "UnAuthorized"){
                            localStorage.clear();
                            window.location.reload(true)
                          }
                        setSearchData(res.data)
                        axios({
                            method: "POST",
                            url: `${NODE_API_URL}/ccmode/fetchCCDiamondCount`,
                            headers: {
                                "Access-Control-Allow-Origin": "*",
                                "Content-Type": "application/json",
                                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                              },
                            data: {
                                ...FinalObject,
                                diamond_type:FinalObject["fancy_color_diamond"] === "YES"?diamond_type+"F":diamond_type
                            },
                        }).then(countres => {
                            if(countres && countres.data){
                                if(countres.data.message === "UnAuthorized"){
                                    localStorage.clear();
                                    window.location.reload(true)
                                  }
                                setStoneCount(countres.data.diamondcount)
                            }
                        })
                    }
                    else{
                        setSearchData([])
                        setStoneCount(0)
                    }
                }).catch(err => {
                    // setLoader(false)
                })
            }
            localStorage.setItem("themesearch",JSON.stringify({Formik:formik.values,FinalObject:FinalObject,diamond_type:diamond_type,sorting:sorting}))
        }
    },[formik.values,custompage,diamond_type,sorting,shortlist,fancy,fancyLab])
    useEffect(() => {
        if(custompage > 1){
        let userData = localStorage.getItem("userData");
        let ConsumerId = localStorage.getItem("C_id"); 
        if(userData){
            userData = JSON.parse(userData)
        }
        if(ConsumerId){
            ConsumerId = JSON.parse(ConsumerId)
        }
        let FinalObject = {
            page:custompage,
            user_id:userData.CustomerId,
            ConsumerId:ConsumerId,
            currency:localStorage.getItem("user_currency")
        }
        
        if(formik.values.Shape && formik.values.Shape.length){
            FinalObject["shape"] = formik.values.Shape
        }
        if(formik.values.Cut && formik.values.Cut.length){
            FinalObject["cut"] = formik.values.Cut
        }
        if(formik.values.Clarity && formik.values.Clarity.length){
            FinalObject["clarity"] = formik.values.Clarity
        }
        if(formik.values.Color && formik.values.Color.length){
            FinalObject["color"] = formik.values.Color
        }
        if(fancy && diamond_type === "N"){
            FinalObject["fancy_color_diamond"] = "YES"
        }
        if(fancyLab && diamond_type === "L"){
            FinalObject["fancy_color_diamond"] = "YES"
        }

        if(formik.values.Fancy_Color && formik.values.Fancy_Color.length){
            // FinalObject["fancy_color_diamond"] = "YES"
            FinalObject["fancy_color"] = formik.values.Fancy_Color
        }
        if(formik.values.Fancy_Color_Overtone && formik.values.Fancy_Color_Overtone.length){
            // FinalObject["fancy_color_diamond"] = "YES"
            FinalObject["fancy_overtone"] = formik.values.Fancy_Color_Overtone
        }
        if(formik.values.Fancy_Color_Intensity && formik.values.Fancy_Color_Intensity.length){
            // FinalObject["fancy_color_diamond"] = "YES"
            FinalObject["fancy_intensity"] = formik.values.Fancy_Color_Intensity
        }
        if(formik.values.Lab && formik.values.Lab.length){
            FinalObject["lab"] = formik.values.Lab
        }
        if(formik.values.Polish && formik.values.Polish.length){
            FinalObject["polish"] = formik.values.Polish
        }
        if(formik.values.Symmetry && formik.values.Symmetry.length){
            FinalObject["symmetry"] = formik.values.Symmetry
        }
        if(formik.values.Fluorescence_Intensity && formik.values.Fluorescence_Intensity.length){
            FinalObject["fluorescence"] = formik.values.Fluorescence_Intensity
        }
        if(formik.values.minCarat >= 0){
            FinalObject["min_carat"] = formik.values.minCarat
        }
        if(formik.values.maxCarat >= 0){
            FinalObject["max_carat"] = formik.values.maxCarat
        }
        if(formik.values.minPrice >= 0){
            FinalObject["total_price_from"] = formik.values.minPrice
        }
        if(formik.values.maxPrice >= 0){
            FinalObject["total_price_to"] = formik.values.maxPrice
        }

        if(formik.values.minDepth >= 0){
            FinalObject["depthmin"] = formik.values.minDepth
        }
        if(formik.values.maxDepth >= 0){
            FinalObject["depthmax"] = formik.values.maxDepth
        }
        if(formik.values.minTable >= 0){
            FinalObject["tablemin"] = formik.values.minTable
        }
        if(formik.values.maxTable >= 0){
            FinalObject["tablemax"] = formik.values.maxTable
        }
        if(formik.values.minRatio >= 0){
            FinalObject["ratiomin"] = formik.values.minRatio
        }
        if(formik.values.maxRatio >= 0){
            FinalObject["ratiomax"] = formik.values.maxRatio
        }
        if(sorting === "carat_desc"){
            FinalObject["sort_field"] = "carat"
            FinalObject["sort_order"] = "desc"
        }
        if(sorting === "carat_asc"){
            FinalObject["sort_field"] = "carat"
            FinalObject["sort_order"] = "asc"
        }
        if(sorting === "price_desc"){
            FinalObject["sort_field"] = "price"
            FinalObject["sort_order"] = "desc"
        }
        if(sorting === "price_asc"){
            FinalObject["sort_field"] = "price"
            FinalObject["sort_order"] = "asc"
        }
        if(sorting === "clarity_desc"){
            FinalObject["sort_field"] = "clarity"
            FinalObject["sort_order"] = "desc"
        }
        if(sorting === "clarity_asc"){
            FinalObject["sort_field"] = "clarity"
            FinalObject["sort_order"] = "asc"
        }
        if(sorting === "color_desc"){
            FinalObject["sort_field"] = "color"
            FinalObject["sort_order"] = "desc"
        }
        if(sorting === "color_asc"){
            FinalObject["sort_field"] = "color"
            FinalObject["sort_order"] = "asc"
        }
        let len = Object.keys(FinalObject).length;
            const temp = {...FinalObject};
            let data = "";
            for (let key in temp) {
                len--;
                let arr = temp[key];
                if (len) {
                    if (Array.isArray(arr)) {
                        let str = "";
                        str += "'" + arr.join("','") + "'";
                        data += `{"FilterName": "${key}","FilterValue":"${str}" }, `;
                    } else {
                        data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"},`;
                    }
                } else {
                    if (Array.isArray(arr)) {
                        let str = "";
                        str += "'" + arr.join("','") + "'";
                        data += `{"FilterName": "${key}","FilterValue":"${str}" } `;
                    } else {
                        data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"}`;
                    }
                }
            }
            // const finalData = `{ "JsonData":  [${data}] , "CustomerId": ${userData.CustomerId}, "ConsumerId":${ConsumerId=== "" ? "-1" : ConsumerId}, "WLDiamondType": "${diamond_type}" }`;
            // axios({
            //     method: "POST",
            //     url: SEARCH_LOG,
            //     headers: { "Content-Type": "application/json" },
            //     data: finalData,
            // })
            //     .then(function(res) {
            //         // console.log(res);
            //     })
            //     .catch(function(res) {
            //         console.log(res);
            //     });
        setLoader(true)
            if (timerId) {
                clearTimeout(timerId);
            }
            // Cancel the previous request if it exists
            if (cancel) {
                cancel();
              }
      
              // Create a new cancel token for the current request
              const newCancelToken = new CancelToken(function executor(c) {
                cancel = c;
              });
            const newTimerId = setTimeout(() => {
            let LogObj = {...FinalObject,diamond_type:diamond_type}
            delete LogObj.token
            if(LogObj.fancy_color_diamond === "YES"){
                delete LogObj.color
            }else{
                delete LogObj.fancy_color
                delete LogObj.fancy_intensity
                delete LogObj.fancy_intensity
            }
            axios({
                method: "POST",
                url: `${NODE_API_URL}/ccmode/storeUserACtivity`,
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                  },
                data: {
                    user_id:FinalObject.user_id,
                    activity_type:"Ccmode Search",
                    user_agent:navigator.userAgent,
                    activity_data:LogObj,
                    ip_address:localStorage.getItem("ipData"),
                    consumer_id:ConsumerId,
                    diamond_type:diamond_type
                },
                cancelToken:newCancelToken
            }).then(res => {
                if(res.data.message === "UnAuthorized"){
                    localStorage.clear();
                    window.location.reload(true)
                  }
            }).catch(error => {
            })
          }, 6000);
          setTimerId(newTimerId);
            if(diamond_type === "N"){
                if (searchcancel) {
                    searchcancel();
                  }
          
                  // Create a new cancel token for the current request
                  const newCancelTokenSearch = new CancelToken(function executor(c) {
                    searchcancel = c;
                  });
                axios({
                    method: "POST",
                    url: `${NODE_API_URL}/ccmode/fetchCCModeNaturalStones`,
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                      },
                    data: FinalObject,
                    cancelToken:newCancelTokenSearch
                }).then(res => {
                    setLoader(false)
                    if(res && res.data && res.data.success){
                        if(res.data.message === "UnAuthorized"){
                            localStorage.clear();
                            window.location.reload(true)
                          }
                        if(searchdata && searchdata.data){
                            console.log({...searchdata},"...searchdata")
                            setSearchData({...searchdata,data:[...searchdata.data,...res.data.data]})
                        }
                        else{
                            setSearchData(res.data)
                        }
                        axios({
                            method: "POST",
                            url: `${NODE_API_URL}/ccmode/fetchCCDiamondCount`,
                            headers: {
                                "Access-Control-Allow-Origin": "*",
                                "Content-Type": "application/json",
                                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                              },
                            data: {
                                ...FinalObject,
                                diamond_type:FinalObject["fancy_color_diamond"] === "YES"?diamond_type+"F":diamond_type
                            },
                        }).then(countres => {
                            if(countres && countres.data){
                                if(countres.data.message === "UnAuthorized"){
                                    localStorage.clear();
                                    window.location.reload(true)
                                  }
                                setStoneCount(countres.data.diamondcount)
                            }
                        })
                    }
                    else{
                        setSearchData([...searchdata])
                        // setStoneCount(0)
                    }
                }).catch(err => {
                    // setLoader(false)
                })
            }
            if(diamond_type === "L"){
                if (searchcancel) {
                    searchcancel();
                  }
          
                  // Create a new cancel token for the current request
                  const newCancelTokenSearch = new CancelToken(function executor(c) {
                    searchcancel = c;
                  });
                axios({
                    method: "POST",
                    url: `${NODE_API_URL}/ccmode/fetchCCModeLabStones`,
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                      },
                    data: FinalObject,
                    cancelToken:newCancelTokenSearch
                }).then(res => {
                    setLoader(false)
                    if(res && res.data && res.data.success){
                        if(res.data.message === "UnAuthorized"){
                            localStorage.clear();
                            window.location.reload(true)
                          }
                        if(searchdata && searchdata.data){
                            console.log({...searchdata},"...searchdata")
                            setSearchData({...searchdata,data:[...searchdata.data,...res.data.data]})
                        }
                        else{
                            setSearchData(res.data)
                        }
                        axios({
                            method: "POST",
                            url: `${NODE_API_URL}/ccmode/fetchCCDiamondCount`,
                            headers: {
                                "Access-Control-Allow-Origin": "*",
                                "Content-Type": "application/json",
                                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                              },
                            data: {
                                ...FinalObject,
                                diamond_type:FinalObject["fancy_color_diamond"] === "YES"?diamond_type+"F":diamond_type
                            },
                        }).then(countres => {
                            if(countres && countres.data){
                                if(countres.data.message === "UnAuthorized"){
                                    localStorage.clear();
                                    window.location.reload(true)
                                  }
                                setStoneCount(countres.data.diamondcount)
                            }
                        })
                    }
                    else{
                        setSearchData([...searchdata])
                        // setStoneCount(0)
                    }
                }).catch(err => {
                    // setLoader(false)
                })
            }
            localStorage.setItem("themesearch",JSON.stringify({Formik:formik.values,FinalObject:FinalObject,diamond_type:diamond_type,sorting:sorting}))
        }
    },[custompage])
    useEffect(() => {
        setCustomPage(1)
    },[formik.values,diamond_type,sorting,fancy,fancyLab])
    const onScroll = () => {
        if (
            window.innerHeight + document.documentElement.scrollTop + 1 >=
                document.documentElement.scrollHeight
        ) {
            console.log(searchdata.data.length,"InFinite")
            if(searchdata && searchdata.data.length && stoneCount !== searchdata.data.length){
                setCustomPage(custompage + 1)
            }
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, [searchdata]);
       return (
        <>
           <div className="row m-0">
                <div className="col-md-12 d-flex justify-content-center mb-5">
                    {diamond_type === "N" ?
                        !fancy ? 
                            naturalData[0] && naturalData[0].shape ? <Shape formik={formik} naturalData={naturalData} language={language}/> : null
                            : naturalFancyData[0] && naturalFancyData[0].shape ? <ShapeFancy formik={formik} naturalData={naturalData} naturalFancyData={naturalFancyData} language={language}/> : null
                        : 
                        !fancyLab ? 
                            labData[0] && labData[0].lab_shape ? <ShapeLab formik={formik} labData={labData} language={language}/> : null
                            : labFancyData[0] && labFancyData[0].lab_shape ? <ShapeLabFancy formik={formik} labFancyData={labFancyData} language={language}/>: null
                    }
                </div>
                {
                    !hidePrice && (
                        <div className="col-md-6">
                            {diamond_type === "N" ?
                                !fancy ? 
                                    <Price formik={formik} naturalData={naturalData} language={language} location={location}/> 
                                    : <PriceFancy formik={formik} naturalData={naturalData} naturalFancyData={naturalFancyData} language={language} location={location}/>
                                : 
                                !fancyLab ?
                                    <PriceLab formik={formik} labData={labData} language={language} location={location}/>
                                    : <PriceLabFancy formik={formik} labFancyData={labFancyData} language={language} location={location}/>
                            }
                        </div>
                    )
                }                
                <div className={`col-md-${hidePrice ? "12" : "6"}`}>
                    {diamond_type === "N" ?
                        !fancy ? 
                            <Carat formik={formik} naturalData={naturalData} language={language} location={location}/> 
                            : <CaratFancy formik={formik} naturalData={naturalData} naturalFancyData={naturalFancyData} language={language} location={location}/> 
                        :
                        !fancyLab ?
                            <CaratLab formik={formik} labData={labData} language={language} location={location}/> 
                            : <CaratLabFancy formik={formik} labFancyData={labFancyData} language={language} location={location}/> 
                    }
                </div>
                {diamond_type === "N" ?
                    <div className={`${fancy ? "col-md-12" : "col-md-4"}`}>
                        { !fancy ?
                            naturalData[0] && naturalData[0].color ? <Color formik={formik} naturalData={naturalData} setFancy={setFancy} fancy={fancy} naturalFancyData={naturalFancyData} language={language}/> : null
                            : <FancyColor formik={formik} naturalData={naturalData} setFancy={setFancy} fancy={fancy} naturalFancyData={naturalFancyData} language={language}/>
                        }
                    </div>
                :
                    <div className={`${fancyLab ? "col-md-12" : "col-md-4"}`}>
                        { !fancyLab ?
                            <ColorLab formik={formik} labData={labData} setFancyLab={setFancyLab} fancyLab={fancyLab} labFancyData={labFancyData} language={language}/>
                            : <FancyLabColor formik={formik}  setFancyLab={setFancyLab} fancyLab={fancyLab} labFancyData={labFancyData} labData={labData} language={language}/>
                        }
                    </div>
                }
                <div className="col-md-4">
                    { diamond_type === "N" ?
                        !fancy ? 
                            naturalData[0] && naturalData[0].clarity ? <Clarity formik={formik} naturalData={naturalData} language={language}/> : null
                            : naturalFancyData[0] && naturalFancyData[0].clarity ? <ClarityFancy formik={formik} naturalData={naturalData} naturalFancyData={naturalFancyData} language={language}/> : null
                        : 
                        !fancyLab ? 
                            labData[0] && labData[0].clarity ? <ClarityLab formik={formik} labData={labData} language={language}/> : null
                            : labFancyData[0] && labFancyData[0].lab_shape ? <ClarityLabFancy formik={formik} labFancyData={labFancyData} language={language}/> : null
                    }
                </div>
                <div className="col-md-4">
                    { diamond_type === "N" ?  
                        !fancy ? 
                            naturalData[0] && naturalData[0].cut ? <Cut formik={formik} naturalData={naturalData} language={language}/> : null
                            : naturalFancyData[0] && naturalFancyData[0].cut ?  <CutFancy formik={formik} naturalFancyData={naturalFancyData} language={language}/>:null
                        : 
                        !fancyLab ?
                            labData[0] && labData[0].lab_cut ? <CutLab formik={formik} labData={labData} language={language}/> : null
                            :  labFancyData[0] && labFancyData[0].lab_cut ? <CutLabFancy formik={formik} labFancyData={labFancyData} language={language}/>: null
                    }
                </div>
            </div>
        </>
    );
};
