import React, {  useState , useEffect} from "react";
import { GridCardContent } from "./card/gridCardContent";
import {   useHistory,useLocation } from 'react-router-dom';
import { FaList,FaLongArrowAltLeft } from "react-icons/fa";
// import { GetDiamondDB } from "../../../_helpers/IndexedDB";
import axios from "axios";
import { NODE_API_URL } from "../../../../env_config";
import SortingTheme1 from "./SortingTheme1";
import GridSkeleton from "../../GridSkeleton";
import { useSelector } from "react-redux";
const CancelToken = axios.CancelToken;
// let cancel;
let searchcancel;
export const GridCardTheme1 = (props) => {
    // console.log(diamondsearch.value,"diamondsearch.value")
    const [loader,setLoader] =useState(false)
    const [count, setCount] = React.useState(0);
    // const [start,setStart]=React.useState(0);
    // const [page,setPage] = React.useState(100);
    // const [actData, setactData] = React.useState([]);
    const history = useHistory();
    const location = useLocation();
    // console.log(location.state,"locationlocation")
    // const natData = diamondsearch.value
    const {
        // hideCertificateNumber,
        // hideEstimatedShippingDays,
        hidePrice,
      } = useSelector((state) => {
        return state.CcModeSettingReducer;
      });    
    const [natData,setNatData] = useState([])
    const [stoneParams,setStoneParams] = useState()
    const [sorting,setSorting] = useState("")
    window.searchData = natData;
    const [infiniteloader,setinfiniteLoader] = useState(false)
    React.useEffect(() => {
        if(location.state.diamondparams){
            if(location.state.diamondparams.diamond_type === "N"){
                if (searchcancel) {
                    searchcancel();
                  }
          
                  // Create a new cancel token for the current request
                  const newCancelTokenSearch = new CancelToken(function executor(c) {
                    searchcancel = c;
                  });
                axios({
                    method: "POST",
                    url: `${NODE_API_URL}/ccmode/fetchCCModeNaturalStones`,
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                      },
                    data: {...location.state.diamondparams,currency:localStorage.getItem("user_currency")},
                    cancelToken:newCancelTokenSearch
                }).then(res => {
                    setinfiniteLoader(false)
                    setLoader(false)
                    if(res && res.data && res.data.success){
                        if(res.data.message === "UnAuthorized"){
                            localStorage.clear();
                            window.location.reload(true)
                          }
                        setNatData(res.data.data)
                        axios({
                            method: "POST",
                            url: `${NODE_API_URL}/ccmode/fetchCCDiamondCount`,
                            headers: {
                                "Access-Control-Allow-Origin": "*",
                                "Content-Type": "application/json",
                                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                              },
                            data: {
                                ...location.state.diamondparams,
                                diamond_type:location.state.diamondparams["fancy_color_diamond"] === "YES"?location.state.diamondparams.diamond_type+"F":location.state.diamondparams.diamond_type
                            },
                        }).then(countres => {
                            
                            if(countres && countres.data){
                                if(countres.data.message === "UnAuthorized"){
                                    localStorage.clear();
                                    window.location.reload(true)
                                  }
                                setCount(countres.data.diamondcount)
                            }
                        })
                    }
                    else{
                        

                    }
                }).catch(err => {
                    
                })
            }
            if(location.state.diamondparams.diamond_type === "L"){
                if (searchcancel) {
                    searchcancel();
                  }
          
                  // Create a new cancel token for the current request
                  const newCancelTokenSearch = new CancelToken(function executor(c) {
                    searchcancel = c;
                  });
                axios({
                    method: "POST",
                    url: `${NODE_API_URL}/ccmode/fetchCCModeLabStones`,
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                      },
                    data: {...location.state.diamondparams,currency:localStorage.getItem("user_currency")},
                    cancelToken:newCancelTokenSearch
                }).then(res => {
                    setinfiniteLoader(false)
                    setLoader(false)
                    if(res && res.data && res.data.success){
                        if(res.data.message === "UnAuthorized"){
                            localStorage.clear();
                            window.location.reload(true)
                          }
                        setNatData(res.data.data)
                        axios({
                            method: "POST",
                            url: `${NODE_API_URL}/ccmode/fetchCCDiamondCount`,
                            headers: {
                                "Access-Control-Allow-Origin": "*",
                                "Content-Type": "application/json",
                                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                              },
                            data: {
                                ...location.state.diamondparams,
                                diamond_type:location.state.diamondparams["fancy_color_diamond"] === "YES"?location.state.diamondparams.diamond_type+"F":location.state.diamondparams.diamond_type
                            },
                        }).then(countres => {
                            if(countres && countres.data){
                                if(countres.data.message === "UnAuthorized"){
                                    localStorage.clear();
                                    window.location.reload(true)
                                  }
                                setCount(countres.data.diamondcount)
                            }
                        })
                    }
                    else{
                        
                    }
                }).catch(err => {
                    
                })
            }
        }
        setNatData(location.state.diamonddata)
        setStoneParams(location.state.diamondparams)
        if(location.state.diamondparams && location.state.diamondparams.sort_field && location.state.diamondparams.sort_order){
            setSorting(`${location.state.diamondparams.sort_field}_${location.state.diamondparams.sort_order}`)
        }
    }, []);

    useEffect(() => {
        fetchData(stoneParams);
    }, [stoneParams]);

    const fetchData = (stoneParams) => {
        if(stoneParams && stoneParams.page > 1){
            setinfiniteLoader(true)
            if(stoneParams.diamond_type === "N"){
                axios({
                    method: "POST",
                    url: `${NODE_API_URL}/ccmode/fetchCCModeNaturalStones`,
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                      },
                    data: {...stoneParams,currency:localStorage.getItem("user_currency")},
                }).then(res => {
                    if(res.data.message === "UnAuthorized"){
                        localStorage.clear();
                        window.location.reload(true)
                      }
                    setinfiniteLoader(false)
                    if(res && res.data && res.data.success){
                        setNatData([
                            ...natData,
                            ...res.data.data
                        ])
                    }
                    else{
    
                    }
                }).catch(err => {
                    
                })
            }
            if(stoneParams.diamond_type === "L"){
                axios({
                    method: "POST",
                    url: `${NODE_API_URL}/ccmode/fetchCCModeLabStones`,
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                      },
                    data: {...stoneParams,currency:localStorage.getItem("user_currency")},
                }).then(res => {
                    setinfiniteLoader(false)
                    if(res && res.data && res.data.success){
                        if(res.data.message === "UnAuthorized"){
                            localStorage.clear();
                            window.location.reload(true)
                          }
                        setNatData([
                            ...natData,
                            ...res.data.data
                        ])
                    }
                    else{
                        
                    }
                }).catch(err => {
                    
                })
            }
        }
        // setTimeout(function() { 
        //     setNatData([
        //         ...natData
        //     ]);
        // setinfiniteLoader(false)

        // }, 2000);
    };
    //  console.log(items,"items")

    const onScroll = () => {
        if (
            window.innerHeight + document.documentElement.scrollTop + 1 >=
                document.documentElement.scrollHeight
        ) {
            setStoneParams({
                ...stoneParams,
                page:stoneParams.page + 1
            })
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, [natData]);
    const natList = () =>{
        history.push({
            pathname: "search-result",
            state: {
                diamonddata:[...natData.slice(0,100)],
                diamondparams:location.state.diamondparams
            },
            // state: decompress(res.data.ProductDetails),
        });
    }
    const backBtn = () =>{
        history.push({  pathname: '/searchdiamond'})
    }
//    console.log(stoneParams,"stoneParams")
    useEffect(() => {
        // console.log(sorting,"sortingsorting")
        if(sorting){
            setLoader(true)
            window.scrollTo(0, 0)
            if(stoneParams){
                let SortingStoneParams = {...stoneParams}
                SortingStoneParams["page"] = 1
                if(sorting === "carat_desc"){
                    SortingStoneParams["sort_field"] = "carat"
                    SortingStoneParams["sort_order"] = "desc"
                }
                if(sorting === "carat_asc"){
                    SortingStoneParams["sort_field"] = "carat"
                    SortingStoneParams["sort_order"] = "asc"
                }
                if(sorting === "price_desc"){
                    SortingStoneParams["sort_field"] = "price"
                    SortingStoneParams["sort_order"] = "desc"
                }
                if(sorting === "price_asc"){
                    SortingStoneParams["sort_field"] = "price"
                    SortingStoneParams["sort_order"] = "asc"
                }
                if(sorting === "clarity_desc"){
                    SortingStoneParams["sort_field"] = "clarity"
                    SortingStoneParams["sort_order"] = "desc"
                }
                if(sorting === "clarity_asc"){
                    SortingStoneParams["sort_field"] = "clarity"
                    SortingStoneParams["sort_order"] = "asc"
                }
                if(sorting === "color_desc"){
                    SortingStoneParams["sort_field"] = "color"
                    SortingStoneParams["sort_order"] = "desc"
                }
                if(sorting === "color_asc"){
                    SortingStoneParams["sort_field"] = "color"
                    SortingStoneParams["sort_order"] = "asc"
                }
                if(stoneParams.diamond_type === "N"){
                    axios({
                        method: "POST",
                        url: `${NODE_API_URL}/ccmode/fetchCCModeNaturalStones`,
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                          },
                        data: {...SortingStoneParams,currency:localStorage.getItem("user_currency")},
                    }).then(res => {
                        setinfiniteLoader(false)
                        setLoader(false)
                        if(res && res.data && res.data.success){
                            if(res.data.message === "UnAuthorized"){
                                localStorage.clear();
                                window.location.reload(true)
                              }
                            history.push({
                                pathname: "/grid",
                                state: {
                                    diamonddata:res.data.data,
                                    diamondparams:SortingStoneParams
                                },
                                // state: decompress(res.data.ProductDetails),
                            });
                            setStoneParams(SortingStoneParams)
                            setNatData([
                                ...res.data.data
                            ])
                        }
                        else{
        
                        }
                    }).catch(err => {
                        setLoader(false)
                    })
                }
                if(stoneParams.diamond_type === "L"){
                    axios({
                        method: "POST",
                        url: `${NODE_API_URL}/ccmode/fetchCCModeLabStones`,
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                          },
                        data: {...SortingStoneParams,currency:localStorage.getItem("user_currency")},
                    }).then(res => {
                        setinfiniteLoader(false)
                        setLoader(false)
                        if(res && res.data && res.data.success){
                            if(res.data.message === "UnAuthorized"){
                                localStorage.clear();
                                window.location.reload(true)
                              }
                            history.push({
                                pathname: "/grid",
                                state: {
                                    diamonddata:res.data.data,
                                    diamondparams:SortingStoneParams
                                },
                                // state: decompress(res.data.ProductDetails),
                            });
                            setStoneParams(SortingStoneParams)
                            setNatData([
                                ...res.data.data
                            ])
                        }
                        else{
                            
                        }
                    }).catch(err => {
                        setLoader(false)
                    })
                }
            }
        }
    },[sorting])
    console.log(infiniteloader,"infiniteloaderinfiniteloader")
    console.log(loader,"loaderloader")
    return (
        <>
            {/* <InfiniteScroll
                dataLength={items.length} //This is important field to render the next data
                next={fetchData}
                hasMore={noMore}
                loader={<h4>Loading...</h4>}
                endMessage={
                    <p style={{ textAlign: "center" }}>
                        <b>Yay! You have seen it all</b>
                    </p>
                }
            > */}
                <div className={`container-fluid theme1 ${natData[0] && natData[0].diamond_type === "L" ? "labTab" : natData[0] && natData[0].diamond_type === "N" ? "natTab" : ""}`}>
                   <div className="card">
                        <div className="card-header d-flex justify-content-between p-2 custom-z-index align-items-center" >
                            <div className="card-title m-0 d-flex align-items-center">
                                {/* <h3 className="font-weight-bolder">{props.language.CCMODE_THEME1_GRID_STOCKLIST}</h3> */}
                                <span className="cursor-pointer my-2 text-primary" onClick={backBtn}><FaLongArrowAltLeft /> {props.language.CCMODE_THEME1_GRID_GOTOSEARCHBTN}</span>
                            </div>
                            <div className="card-toolbar d-flex">
                                <div className="custom-result-box mr-2">
                                    {props.language.TOTAL_STONE_COUNT} : <span className="font-weight-bold">{count}</span>
                                </div>
                                {/* <button className="btn btn-primary btn-sm mr-3" onClick={selectSession}><FaList/> selectSession</button> */}
                                {/* <SortingTheme1 setSorting={setSorting} sorting={sorting}/> */}
                                <button className="btn btn-primary mr-2" onClick={natList}><FaList/> {props.language.CCMODE_THEME1_GRID_LISTBTN}</button>
                                <SortingTheme1 setSorting={setSorting} sorting={sorting} language={props.language} hidePrice={hidePrice}/>
                                {/* <button className="btn btn-warning btn-sm " onClick={backBtn}><FaLongArrowAltLeft /> {props.language.CCMODE_THEME1_GRID_GOTOSEARCHBTN}</button> */}
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="d-flex justify-content-between flex-wrap m-0 mt-5">
                                {/* {JSON.stringify(natData)} */}
                                {loader ? <GridSkeleton/>  :
                                    <>
                                        {natData.map((val) => {
                                            return (
                                                <GridCardContent
                                                    key={val.ProductId}
                                                    {...val}
                                                    setNatData={setNatData}
                                                    natData={natData}
                                                    stoneParams={stoneParams}
                                                    language={props.language}
                                                />
                                            );
                                        })}
                                        {infiniteloader?<GridSkeleton/>:""}
                                    </>
                                }
                            </div>
                            {natData.length % 100?<div className="text-center mt-5">No More Records</div>:""}
                        </div>
                   </div>
                </div>
            {/* </InfiniteScroll> */}
        </>
    );
};
