/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useState,useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { GetCookies } from "../_metronic/_helpers/Cookies";
import axios from "axios";
import { LOGO_BASE_URL, NODE_API_URL } from "../env_config";
import languages from "../_metronic/layout/components/extras/languages.json" 
import { setCcModeSetting } from "../redux/commonRedux/common.actions";
export function Routes() {
    const dispatch = useDispatch();
    const [theme, setTheme] = useState("loader");
    const [themeData, setThemeData] = useState([]);
    const [ruleData, setRuleData] = useState([]);
    const [naturalData, setNaturalData] = useState([]);
    const [naturalFancyData, setNaturalFancyData] = useState([]);
    const [labData, setLabData] = useState([]);
    const [labFancyData, setLabFancyData] = useState([]);
    const [user, setUser] = useState([]);
    const [language,setLanguage] = React.useState("en")
    const { isAuthorized } = useSelector(
        ({ auth }) => ({
            isAuthorized: auth.user != null,
        }),
        shallowEqual
    );
    function mergeArrayOfObjects(arrayOfObjects) {
      if(!arrayOfObjects.length)
      return {}
      // Start with the first object, and then merge the rest one by one
      return arrayOfObjects.reduce((merged, currentObj) => mergeObjectsDynamic(merged, currentObj));
    }
    
    function mergeObjectsDynamic(obj1, obj2) {
      const merged = {};
    
      for (const key in obj1) {
        if (obj1.hasOwnProperty(key)) {
          const value1 = obj1[key];
          const value2 = obj2[key];
    
          // Handle string lists (comma-separated values)
          if (typeof value1 === 'string' && typeof value2 === 'string' && value1.includes(',') && value2.includes(',')) {
            merged[key] = mergeLists(value1, value2);
          }
          // Handle null or empty string values
          else if (value1 === null || value1 === "") {
            merged[key] = value2;
          }
          else if (value2 === null || value2 === "") {
            merged[key] = value1;
          }
          // Handle number ranges (min and max values)
          else if (typeof value1 === 'number' && typeof value2 === 'number') {
            merged[key] = key.includes('min') ? Math.min(value1, value2) : Math.max(value1, value2);
          }
          // Default case: keep value1 unless it's empty or null
          else {
            merged[key] = value1 !== null && value1 !== "" ? value1 : value2;
          }
        }
      }
    
      return merged;
    }
    
    // Helper function to merge comma-separated lists and remove duplicates
    function mergeLists(list1, list2) {
      const set = new Set(list1.split(',').concat(list2.split(',')));
      return Array.from(set).join(',');
    }
    
    
    
    useEffect(() => {
      if (isAuthorized){
        GetCookies("resData").then(response => {
          if(response){
            let userData = JSON.parse(response)
            setUser(JSON.parse(response));
            axios({
              method: "POST",
              url: `${NODE_API_URL}/fetchCCMOdeSetting`,
              // url: `https://api.staging.global.diamondsupload.com/INTL/fetchCCModeSetting`,
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
              },
              data: {
                CustomerId:userData.CustomerId
              }
            })
            .then(function(res) {
              if(res && res.data && res.data.length){
                if(res.data.message === "UnAuthorized"){
                  localStorage.clear();
                  window.location.reload(true)
                }
                setThemeData(res.data[0])
                setTheme(res.data[0].Theme)
                if(res.data[0] && res.data[0].AllowLogin.data[0] === 0){
                    localStorage.clear();
                    window.location.replace("/")
                }
                dispatch(
                  setCcModeSetting({
                    hideCertificateNumber: res.data[0].hideCertificateNumber,
                    hideEstimatedShippingDays:
                      res.data[0].hideEstimatedShippingDays,
                    hidePrice: res.data[0].hidePrice,
                    displayName: res.data[0].displayName,
                    displaybuybtn:res.data[0].displaybuybtn
                  })
                );
                // console.log("here1");
                // console.log(res.data[0]);
              }
              else{
                localStorage.clear();
                window.location.replace("/")
              }
            })
            .catch((res) => {
                console.warn(res);
            });

            // for rules 

            axios({
              method: "POST",
              url: `${NODE_API_URL}/ccmode/fetchCCModerule`,
              // url: `https://api.staging.global.diamondsupload.com/INTL/fetchCCModeSetting`,
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
              },
              data: {
                user_id:userData.CustomerId
              }
            })
            .then(function(res) {
              if(res && res.data){
                if(res.data.message === "UnAuthorized"){
                  localStorage.clear();
                  window.location.reload(true)
                }
                setRuleData(res.data.data)
                let checkstatus = res.data.data.map(value => value.status).every(element => element === 0)
                if(checkstatus){
                  localStorage.clear();
                  window.location.replace("/")
                }
                // setTheme(res.data[0].Theme)
                // console.log(res.data.data);
                // console.log("here2");
                // const resNaturalData = res.data.data.find(element => element.naturaldiamond === 1)
                // const resNaturalfancyData = res.data.data.find(element => element.naturalfancydiamond === 1)
                // const resLabData = res.data.data.find(element => element.labdiamond === 1)
                // const resLabFancyData = res.data.data.find(element => element.labfancydiamond === 1)
                let naturalMerged = mergeArrayOfObjects(res.data.data.filter(element => element.naturaldiamond === 1));
                let naturalruleid = res.data.data.filter(element => element.naturaldiamond === 1).map(item => item.rule_id).join(',')
                let naturaldata = Object.keys(naturalMerged).length?[{...naturalMerged,rule_id:naturalruleid}]:[]
                setNaturalData(naturaldata)

                let naturalFancyMerged = mergeArrayOfObjects(res.data.data.filter(element => element.naturalfancydiamond === 1));
                let naturalfancyruleid = res.data.data.filter(element => element.naturalfancydiamond === 1).map(item => item.rule_id).join(',')
                let naturalfancydata = Object.keys(naturalFancyMerged).length?[{...naturalFancyMerged,rule_id:naturalfancyruleid}]:[]
                setNaturalFancyData(naturalfancydata)

                let labgrownMerged = mergeArrayOfObjects(res.data.data.filter(element => element.labdiamond === 1));
                let labgrownruleid = res.data.data.filter(element => element.labdiamond === 1).map(item => item.rule_id).join(',')
                let labgrowndata = Object.keys(labgrownMerged).length?[{...labgrownMerged,rule_id:labgrownruleid}]:[]
                setLabData(labgrowndata)

                let labgrownFancyMerged = mergeArrayOfObjects(res.data.data.filter(element => element.labfancydiamond === 1));
                let labgrownfancyruleid = res.data.data.filter(element => element.labfancydiamond === 1).map(item => item.rule_id).join(',')
                let labgrownfancydata = Object.keys(labgrownFancyMerged).length?[{...labgrownFancyMerged,rule_id:labgrownfancyruleid}]:[]
                setLabFancyData(labgrownfancydata)
                
              }
            })
            .catch((res) => {
                console.warn(res);
            });
          }
        })
        GetCookies("language").then(response => {  
          if(response){
              setLanguage(response)
          }
      })
      axios({
        method: 'GET',
        url:"https://api.ipify.org",
    })
      .then(function (res) {
          localStorage.setItem("ipData",res.data)
      })
      .catch((res) => {
        
      });
      }
     
    }, [isAuthorized]);

    // console.log("naturalData",naturalData)
    // console.log("naturalfancyData",naturalFancyData)
    // console.log("labData",labData)
    // console.log("labFancyData",labFancyData)
    // console.log("-----------------------------------------------------")
    // console.log(languages[language],"LANGUAGE")
    return (
        <>
          {(user && themeData && ruleData) &&
            <style>
              {`

                
                .labTab .search_accordion .card .card-header {
                  background-color: ${themeData.LabPrimaryColor || "#000000"}!important;
                  color: ${themeData.LabFontColor || "#ffffff"};
                }
                .labTab .search_btn_check > input:checked ~ .selectBox {
                    background-color:${themeData.LabPrimaryColor || "#000000"};
                    color: ${themeData.LabFontColor || "#ffffff"};
                }
                theme3 .labTab .search_btn_check > input:checked ~ .selectBox {
                  background-color:${themeData.LabPrimaryColor || "#000000"}!important;
                  border-radius:8px;
                }
                .labTab .selectBox:hover ,.theme3 .labTab .selectBox:hover{
                    border: 1px solid ${themeData.LabPrimaryColor || "#000000"}!important;
                }
                .labTab .search_btn_check > .selectBox {
                    background-color: ${themeData.LabPrimaryColor || "#000000"}10;
                    border: 1px solid ${themeData.LabPrimaryColor || "#000000"}40;
                }
                .theme3 .labTab .search_btn_check > .selectBox{
                  background-color: ${themeData.LabPrimaryColor || "#000000"}15;
                  border: 1px solid #00217300;
              }
                .labTab .search_btn_check > input:checked ~ .selectBox span {
                  color: ${themeData.LabFontColor || "#ffffff"};
                }

                .labTab .search_btn_check > input:checked ~ .selectBox g [fill] {
                  stroke: ${themeData.LabFontColor || "#ffffff"};
                  fill: ${themeData.LabFontColor || "#ffffff"};
                }

                .labTab .search_btn_check > input:checked ~ .selectBox g rect[fill] {
                  stroke: ${themeData.LabFontColor || "#ffffff"}00;
                }
                .labTab .text-primary{color: ${themeData.LabPrimaryColor || "#000000"}!important;}
                .labTab.text-primary{color: ${themeData.LabPrimaryColor || "#000000"}!important;}
                .labTab .btn.btn-primary {
                  color:${themeData.LabFontColor || "#ffffff"}!important;
                  background-color: ${themeData.LabPrimaryColor || "#000000"};
                  border-color: ${themeData.LabPrimaryColor || "#000000"};
                }

                .labTab .btn.btn-primary:hover:not(.btn-text):not(:disabled):not(.disabled),  .labTab .btn.btn-primary:focus:not(.btn-text),  .labTab .btn.btn-primary.focus:not(.btn-text) {
                  color:${themeData.LabFontColor || "#ffffff"}!important;
                  background-color: ${themeData.LabPrimaryColor || "#000000"}cc;
                  border-color: ${themeData.LabPrimaryColor || "#000000"}cc;
                }

                .labTab .btn.btn-primary:not(:disabled):not(.disabled):active:not(.btn-text), .labTab .btn.btn-primary:not(:disabled):not(.disabled).active,  .labTab .show > .btn.btn-primary.dropdown-toggle, .labTab .show .btn.btn-primary.btn-dropdown {
                  color:${themeData.LabFontColor || "#ffffff"}!important;
                  background-color: ${themeData.LabPrimaryColor || "#000000"}cc;
                  border-color: ${themeData.LabPrimaryColor || "#000000"}cc;
                }
                .labTab .btn.btn-light-primary {
                  color:${themeData.LabPrimaryColor || "#000000" || "#000000"}!important;
                  background-color: ${themeData.LabPrimaryColor || "#000000"}10;
                  border-color: ${themeData.LabPrimaryColor || "#000000"}50;
                }

                .labTab .btn.btn-light-primary:hover:not(.btn-text):not(:disabled):not(.disabled),  .labTab .btn.btn-light-primary:focus:not(.btn-text),  .labTab .btn.btn-light-primary.focus:not(.btn-text) {
                  color:${themeData.LabPrimaryColor || "#000000"}!important;
                  background-color: ${themeData.LabPrimaryColor || "#000000"}10;
                  border-color: ${themeData.LabPrimaryColor || "#000000"};
                }

                .labTab .btn.btn-light-primary:not(:disabled):not(.disabled):active:not(.btn-text), .labTab .btn.btn-light-primary:not(:disabled):not(.disabled).active,  .labTab .show > .btn.btn-light-primary.dropdown-toggle, .natTab .show .btn.btn-light-primary.btn-dropdown {
                 color:${themeData.LabPrimaryColor || "#000000"}!important;
                  background-color: ${themeData.LabPrimaryColor || "#000000"}10;
                  border-color: ${themeData.LabPrimaryColor || "#000000"};
                }
                  .labTab .btn-text-primary{
                    color: ${themeData.LabPrimaryColor || "#000000"}!important;
                }
                .labTab .text-hover-primary{
                    color: ${themeData.LabPrimaryColor || "#000000"}!important;
                }
                    .labTab .btn-text-info{
                    color: ${themeData.LabPrimaryColor || "#000000"}50!important;
                }
                .labTab .btn.btn-hover-light-info:hover:not(.btn-text):not(:disabled):not(.disabled), .labTab .btn.btn-hover-light-info:focus:not(.btn-text), .labTab .btn.btn-hover-light-info.focus:not(.btn-text){
                  background-color: ${themeData.LabPrimaryColor || "#000000"}10;
                }
                .labTab .btn.btn-hover-light-primary:hover:not(.btn-text):not(:disabled):not(.disabled), .labTab .btn.btn-hover-light-primary:focus:not(.btn-text), .labTab .btn.btn-hover-light-primary.focus:not(.btn-text){
                  color:${themeData.LabPrimaryColor || "#000000"}!important;
                  background-color: ${themeData.LabPrimaryColor || "#000000"}10;
                }
                
                .labTab .MuiSlider-colorSecondary {
                    color: ${themeData.LabPrimaryColor || "#000000"};
                }
                .labTab .MuiTabs-indicator{ background-color: ${themeData.LabPrimaryColor || "#000000"};}
                .labTab .custom-seprator{
                  border-top: 1px solid  ${themeData.LabPrimaryColor || "#000000"};
                }
                .labTab .custom-result-box{
                  color: ${themeData.LabPrimaryColor || "#000000"}!important;
                  background-color: ${themeData.LabPrimaryColor || "#000000"}0a !important;
                  border-color: ${themeData.LabPrimaryColor || "#000000"} !important;
                }
                .labTab .MuiTab-textColorSecondary.Mui-selected{
                  color: ${themeData.LabPrimaryColor || "#000000"};
                }
                .labTab .MuiSlider-valueLabel span span {
                  color: ${themeData.LabFontColor || "#ffffff"};
                }
                .labTab .border-primary{ 
                  border-color:${themeData.LabPrimaryColor || "#000000"}!important;
                  color:${themeData.LabPrimaryColor || "#000000"}!important;
                } 
                  .labTab a{
                     color:${themeData.LabPrimaryColor || "#000000"}!important;
                }
                .labTab a:hover{
                     color:${themeData.LabPrimaryColor || "#000000"}!important;
                }

                 .labTab .sorting .MuiOutlinedInput-inputMarginDense {
                 color:${themeData.LabPrimaryColor || "#000000"}!important;
                }
                 .labTab .sorting-menu{
                  color:${themeData.LabPrimaryColor || "#000000"}!important;
                 }
                   .labTab .sorting-menu:hover{
                  color:${themeData.LabPrimaryColor || "#000000"}!important;
                 }
                
               .labTab  .sorting .MuiOutlinedInput-notchedOutline {
                 border-color:${themeData.LabPrimaryColor || "#000000"}!important;
                  
                }
                .labTab .sorting .MuiSelect-icon{
                 color:${themeData.LabPrimaryColor || "#000000"}!important;
                }

                .labTab .btn-info{
                    color:${themeData.LabFontColor || "#ffffff"}!important;
                    background-color:${themeData.LabPrimaryColor || "#000000"}50!important;
                    border: none;
                    

                 }
                  .labTab .btn-info:hover{
                    background-color:${themeData.LabPrimaryColor || "#000000"}50!important;

                 }




                body{
                    background-image:url(${LOGO_BASE_URL}/${themeData.background_image})!important;
                    background-color:${themeData.background_color||"#EEF0F8"};
                    transition: background-image 1s;
                     background-attachment: scroll !important; /* Allow image to scroll with the page */
                    background-size:cover!important;
                }

                .header {
                  background-color: ${themeData.nav_bar_color || "#ffffff"}!important;
                  color: ${themeData.nav_bar_font || "#000000" || "#000000"}!important;
                }
                .header .user-logo-alt-name {
                  color:  ${themeData.nav_bar_font || "#000000"|| "#000000"}!important;
                }
                    
                ::selection {
                    color: ${themeData.NaturalFontColor || "#ffffff"};
                    background: ${themeData.NaturalPrimaryColor || "#000000"};
                }
                body::-webkit-scrollbar {
                  height: 6px;
                  width:  6px;
                }
                /*Track */
                body::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 2px grey;
                    background:#fff
                }
                /*Handle */
                body::-webkit-scrollbar-thumb {

                    background: ${themeData.NaturalPrimaryColor || "#000000"};
                    border-radius: 10px;
                }
                body::-webkit-scrollbar-thumb:hover {
                    background: ${themeData.NaturalPrimaryColor || "#000000"};
                    scrollbar-color: ${themeData.NaturalPrimaryColor || "#000000"}8f rgb(0 33 115 / 56%);
                }
                    
                .c_header.natTab .text-primary{
                  color: ${themeData.nav_bar_font || "#000000"}!important;
                }
                .c_header.natTab .MultiLingual .MuiOutlinedInput-inputMarginDense, .natTab .MuiSelect-icon{
                  color: ${themeData.nav_bar_font || "#000000"}!important;
                } 
                
                .c_header.natTab .symbol.symbol-light-primary .symbol-label{
                  background-color: ${themeData.nav_bar_font || "#000000"}20!important;
                  color:${themeData.nav_bar_font || "#000000"}!important;
                }
                .c_header.natTab .MultiLingual .MuiOutlinedInput-notchedOutline{
                  border-color: ${themeData.nav_bar_font || "#000000"}!important;
                }  

                .c-header-menu{
                  color: ${themeData.nav_bar_font || "#000000"}!important;
                } 
                  .c-header-menu:hover{
                  color: ${themeData.nav_bar_font || "#000000"}!important;
                } 
                  .c_header .navi .navi-item .navi-link:hover,.c_header .navi .navi-item .navi-link:hover .navi-text {
                  color: ${themeData.nav_bar_font || "#000000"}!important;
                  }
                   .c_header .navi .navi-item .navi-link,.c_header .navi .navi-item .navi-link .navi-text {
                  color: ${themeData.nav_bar_font || "#000000"}!important;
                  }

                .natTab .sorting .MuiOutlinedInput-inputMarginDense {
                 color:${themeData.NaturalPrimaryColor || "#000000"}!important;
                }
                 .natTab .btn-info{
                    color:${themeData.NaturalFontColor || "#ffffff"}!important;
                    background-color:${themeData.NaturalPrimaryColor || "#000000"}50!important;
                    border: none;
                    

                 }
                  .natTab .btn-info:hover{
                    background-color:${themeData.NaturalPrimaryColor || "#000000"}50!important;

                 }
                 .sorting-menu,.sorting-menu:hover{
                  color:${themeData.NaturalPrimaryColor || "#000000"}!important;
                 }
                
               .natTab  .sorting .MuiOutlinedInput-notchedOutline {
                 border-color:${themeData.NaturalPrimaryColor || "#000000"}!important;
                  
                }
                .natTab .sorting .MuiSelect-icon{
                 color:${themeData.NaturalPrimaryColor || "#000000"}!important;
                }
                 .natTab .text-hover-primary{
                    color: ${themeData.NaturalPrimaryColor || "#000000"}!important;
                }

                .natTab .border-primary{ 
                    border-color:${themeData.NaturalPrimaryColor || "#000000"}!important;
                    color:${themeData.NaturalPrimaryColor || "#000000"}!important;
                } 
                .natTab .btn-text-primary{
                    color: ${themeData.NaturalPrimaryColor || "#000000"}!important;
                }
                .natTab .btn-text-info{
                    color: ${themeData.NaturalPrimaryColor || "#000000"}50!important;
                    

                }
                .natTab .btn.btn-hover-light-primary:hover:not(.btn-text):not(:disabled):not(.disabled),.natTab .btn.btn-hover-light-primary:focus:not(.btn-text),.natTab .btn.btn-hover-light-primary.focus:not(.btn-text){
                  background-color: ${themeData.NaturalPrimaryColor || "#000000"}10;
                }
                .natTab .btn.btn-hover-light-info:hover:not(.btn-text):not(:disabled):not(.disabled), .natTab .btn.btn-hover-light-info:focus:not(.btn-text), .natTab .btn.btn-hover-light-info.focus:not(.btn-text){
                  background-color: ${themeData.NaturalPrimaryColor || "#000000"}10;
                }
                
                .natTab a{
                    color:${themeData.NaturalPrimaryColor || "#000000"}!important;
                }
                .natTab a:hover{
                    color:${themeData.NaturalPrimaryColor || "#000000"}!important;
                }

                .table-responsive::-webkit-scrollbar {
                  height: 10px;
                  width:  10px;
                }
                /*Track */
                .table-responsive::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 2px grey;
                }
                /*Handle */
                .table-responsive::-webkit-scrollbar-thumb {
                    background: ${themeData.NaturalPrimaryColor || "#000000"};
                    border-radius: 10px;
                }
                .table-responsive::-webkit-scrollbar-thumb:hover {
                    background: ${themeData.NaturalPrimaryColor || "#000000"}8f;
                    scrollbar-color: ${themeData.NaturalPrimaryColor || "#000000"}8f rgb(0 33 115 / 56%);
                }
                .natTab .search_accordion .card .card-header {
                    background-color: ${themeData.NaturalPrimaryColor || "#000000"}!important;
                    color: ${themeData.NaturalFontColor || "#ffffff"};
                }
                .natTab .search_btn_check > input:checked ~ .selectBox {
                    background-color:${themeData.NaturalPrimaryColor || "#000000"};
                    color: ${themeData.NaturalFontColor || "#ffffff"};
                }
                .theme3 .natTab .search_btn_check > .selectBox{
                  background-color: ${themeData.NaturalPrimaryColor || "#000000"}15;
                  border: 1px solid #00217300;
                }
                .natTab .selectBox:hover ,.theme3 .natTab .selectBox:hover{
                    border: 1px solid ${themeData.NaturalPrimaryColor || "#000000"}!important;
                }
                .natTab .search_btn_check > .selectBox {
                    background-color: ${themeData.NaturalPrimaryColor || "#000000"}10;
                    border: 1px solid ${themeData.NaturalPrimaryColor || "#000000"}40;
                }
                .natTab .search_btn_check > input:checked ~ .selectBox span {
                  color: ${themeData.NaturalFontColor || "#ffffff"};
                }
                .natTab .text-primary{color: ${themeData.NaturalPrimaryColor || "#000000"}!important;}
                .natTab.text-primary{color: ${themeData.NaturalPrimaryColor || "#000000"}!important;}
                .natTab .btn.btn-primary {
                  color:${themeData.NaturalFontColor || "#ffffff"}!important;
                  background-color: ${themeData.NaturalPrimaryColor || "#000000"};
                  border-color: ${themeData.NaturalPrimaryColor || "#000000"};
                }

                .natTab .btn.btn-primary:hover:not(.btn-text):not(:disabled):not(.disabled),  .natTab .btn.btn-primary:focus:not(.btn-text),  .natTab .btn.btn-primary.focus:not(.btn-text) {
                  color:${themeData.NaturalFontColor || "#ffffff"}!important;
                  background-color: ${themeData.NaturalPrimaryColor || "#000000"}cc;
                  border-color: ${themeData.NaturalPrimaryColor || "#000000"}cc;
                }

                .natTab .btn.btn-primary:not(:disabled):not(.disabled):active:not(.btn-text), .natTab .btn.btn-primary:not(:disabled):not(.disabled).active,  .natTab .show > .btn.btn-primary.dropdown-toggle, .natTab .show .btn.btn-primary.btn-dropdown {
                  color:${themeData.NaturalFontColor || "#ffffff"}!important;
                  background-color: ${themeData.NaturalPrimaryColor || "#000000"}cc;
                  border-color: ${themeData.NaturalPrimaryColor || "#000000"}cc;
                }
                .natTab .btn.btn-light-primary {
                  color:${themeData.NaturalPrimaryColor || "#000000"}!important;
                  background-color: ${themeData.NaturalPrimaryColor || "#000000"}10;
                  border-color: ${themeData.NaturalPrimaryColor || "#000000"}50;
                }

                .natTab .btn.btn-light-primary:hover:not(.btn-text):not(:disabled):not(.disabled),  .natTab .btn.btn-light-primary:focus:not(.btn-text),  .natTab .btn.btn-light-primary.focus:not(.btn-text) {
                  color:${themeData.NaturalPrimaryColor || "#000000"}!important;
                  background-color: ${themeData.NaturalPrimaryColor || "#000000"}10;
                  border-color: ${themeData.NaturalPrimaryColor || "#000000"};
                }

                .natTab .btn.btn-light-primary:not(:disabled):not(.disabled):active:not(.btn-text), .natTab .btn.btn-light-primary:not(:disabled):not(.disabled).active,  .natTab .show > .btn.btn-light-primary.dropdown-toggle, .natTab .show .btn.btn-light-primary.btn-dropdown {
                 color:${themeData.NaturalPrimaryColor || "#000000"}!important;
                  background-color: ${themeData.NaturalPrimaryColor || "#000000"}10;
                  border-color: ${themeData.NaturalPrimaryColor || "#000000"};
                }
                .natTab .MuiSlider-colorSecondary {
                    color: ${themeData.NaturalPrimaryColor || "#000000"};
                }
                .natTab .MuiSlider-valueLabel span span {
                    color: ${themeData.NaturalFontColor || "#ffffff"};
                }
                .natTab .MuiTabs-indicator{ background-color: ${themeData.NaturalPrimaryColor || "#000000"};}
                .natTab .custom-seprator{
                  border-top: 1px solid  ${themeData.NaturalPrimaryColor || "#000000"};
                }
                .natTab .custom-result-box{
                    color: ${themeData.NaturalPrimaryColor || "#000000"}!important;
                    background-color: ${themeData.NaturalPrimaryColor || "#000000"}0a!important;
                    border-color: ${themeData.NaturalPrimaryColor || "#000000"} !important;
                }
                .natTab .MuiTab-textColorSecondary.Mui-selected{
                  color: ${themeData.NaturalPrimaryColor || "#000000"};
                }
                .natTab .search_btn_check > input:checked ~ .selectBox g [fill] {
                  stroke: ${themeData.NaturalFontColor || "#ffffff"};
                  fill: ${themeData.NaturalFontColor || "#ffffff"};
                }

                .search_btn_check > input:checked ~ .selectBox g .round-svg[fill], .search_btn_check > input:checked ~ .selectBox g .cls-1round[fill], .search_btn_check > input:checked ~ .selectBox g .oval-shape[fill], .search_btn_check > input:checked ~ .selectBox g .cushion-shape1[fill]{
                  fill: none!important;
                }
                
                .natTab .search_btn_check > input:checked ~ .selectBox g rect[fill] {
                  stroke: ${themeData.NaturalFontColor || "#ffffff"}00;
                }

                     
            `}
            </style>
          }
            <Switch>
                {!isAuthorized ? (
                    /*Render auth page when user at `/auth` and not authorized.*/
                    <Route>
                        <AuthPage />
                    </Route>
                ) : (
                    /*Otherwise redirect to root page (`/`)*/
                    <Redirect from="/auth" to="/" />
                )}

                <Route path="/error" component={ErrorsPage} />
                <Route path="/logout" component={Logout} />

                {!isAuthorized ? (
                    /*Redirect to `/auth` when user is not authorized*/
                    <Redirect to="/" />
                ) : (
                    <Layout language={languages[language]} themeData={themeData}>
                        <BasePage 
                          themeData={themeData} 
                          theme={theme} 
                          user={user} 
                          ruleData={ruleData} 
                          naturalData={naturalData} 
                          naturalFancyData={naturalFancyData} 
                          labData={labData} 
                          labFancyData={labFancyData} 
                          language={languages[language]}/>
                    </Layout>
                )}
            </Switch>
        </>
    );
}
